export default async function auth({store, next, to, from}) {
    if (store.getters['auth/isLogined']) {
        return next();
    }
    await store.dispatch('auth/authenticate');
    if (!store.getters['auth/isLogined']) {
        return next({
            name: 'app.home',
        });
    }
    if (!store.getters['auth/user'].can_chat) {
        return next({
            name: 'app.banned',
        });
    }
    return next();
}