<template>
    <div>
        <div class="loader simple-circle"></div>
        <p class="mt-5 text-muted text-center text-sm">{{ text }}</p>
    </div>
</template>
<script>
export default {
    props: ['text']
}
</script>

<style scoped>
    .loader {
        margin: 0 auto;
        margin-top: 15%;
    }
    .loader,.loader:before,.loader:after{
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
    }
    @keyframes circle-loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .loader.simple-circle {
        transform-origin: center center;
        border: var(--line-width, 4px) solid var(--loader-color-secondary, #f2f2f2);
        border-right-color: var(--loader-color-primary, #6fbe44);
        width: var(--loader-width, 100px);
        height: var(--loader-height, 100px);
        border-radius: 50%;
        animation: circle-loader var(--animation-duration, 1s) infinite ease-out;
    }
    .text-sm {
        font-size: 13px;
    }
</style>