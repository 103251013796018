import cookie from "@/helpers/cookie";

export default async function guest({next, to, from, store}) {
    if (store.getters['auth/isLogined']) {
        if (!store.getters['auth/user'].can_chat) {
            return next({
                name: 'app.banned'
            });
        }
        return next({
            name: 'app.conversations'
        });
    }
    if (to.query.refresh) {
        cookie.delete('sso_token');
        return next();
    }
    if (cookie.has('sso_token')) {
        await store.dispatch('auth/authenticate');
        if(store.getters['auth/isLogined']) {
            if (!store.getters['auth/user'].can_chat) {
                return next({
                    name: 'app.banned'
                });
            }
            return next({
                name: 'app.conversations'
            });
        }
        cookie.delete('sso_token');
    }
    return next();
}