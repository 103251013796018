import Vue from 'vue';
import VTooltip from 'v-tooltip';
import App from './App.vue';
import router from './router';
import store from '@/store';
import '@/layouts';
import '@/plugins';
import cookie from '@/helpers/cookie';

Vue.config.productionTip = false;
Vue.use(VTooltip);

if (cookie.has('sso_token')) {
    localStorage.setItem('sso_token', cookie.get('sso_token'));
}
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');
if (token) {
	cookie.set('sso_token', token, 30);
}

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
