import axios from "axios";

export default {
    authenticate({SSOToken}) {
        return axios.post("/authenticate", {
            user_agent: navigator.userAgent || null,
            language: navigator.language || null
        }, {
            headers: {
                SSOToken
            }
        });
    },
    agreeTerm() {
        return axios.post('agree-term');
    },
    toggleNotificationOrSoundStatus({notification_status, sound_status}) {
        return axios.post('toggle-status', {
            notification_status,
            sound_status
        });
    }
}